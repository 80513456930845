<template>
  <Spinner v-show="!loaded"></Spinner>
  <div class="mt--8 pb-5 container" v-if="setting">
    <h1 class="text-center text-white mb-5">{{!this.isEditing ? 'ADD' : 'EDIT'}} Allowed Prefix</h1>
    <b-form v-if="setting">
      <div class="card bg-secondary border-0">
        <b-row class="setting-edit">
          <b-col lg="10" class="mx-auto">
            <b-row class="border-bottom sticky-top bg-white page-header">
              <div class="d-flex align-items-center">
                <b-link @click="$router.go(-1)">
                  <font-awesome-icon icon="arrow-left-long" />
                </b-link>
                <h5 class="text-uppercase p-0 my-3 mx-2 flex-grow-1">Back</h5>
                <b-button :disabled="this.invalid" @click.prevent="saveSettings">{{!this.isEditing ? 'Add' : 'Update'}}</b-button>
              </div>
            </b-row>

              <div lg="3" class="p-3">
                <b-form-group id="prefix" label="prefix"
                  label-for="prefix">
                  <b-form-input class="w-100" id="prefix" v-model=" setting.prefix "
                      name="prefix" type="IP" placeholder="0.0.0.0/24"
                      required></b-form-input>
                </b-form-group>
                <b-form-group label="Status" label-for="enabled" class="mb-0">
                  <b-form-checkbox
                    switch
                    name="enabled"
                    v-model="setting.enabled"
                  />
                </b-form-group>
              </div>

              <h3 class="mt-5" v-if="this.isEditing">History</h3>
              <b-col lg="6" v-if="setting.created_at">
                <p><strong>
                  Added:</strong>
                  {{ _d(new Date(setting.created_at).toISOString()) }}
                </p>
              </b-col>
              <b-col lg="6" v-if="setting.updated_at">
                <p>
                  <strong>Updated:</strong>
                  {{ _d(new Date(setting.updated_at).toISOString()) }}
                </p>
              </b-col>
          </b-col>
        </b-row>
      </div>
    </b-form>
  </div>
</template>
<script>
  import AllowedPrefixesService from "../../services/allowed-prefixes.service";
  import "vue3-toastify/dist/index.css";
  import { notify } from "../../helpers/index";
  import Spinner from "../../components/spinner/spinner.vue";
  import { getFullFormatDateFromISOString } from "../../helpers/index";

  export default {
    name: "AllowedPrefix",
    components: {
      Spinner,
    },
    data: function () {
      return {
        setting: {
          id: '',
          prefix: '',
          uid: '',
          enabled: false,
        },
        isEditing: false,
        invalid: true,
        loaded: false,
        allPrefixes: []
      };
    },
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      },
      allowedPrefixID() {
        return this.$route.params?.id;
      },
    },
    async mounted() {
      if(!!this.allowedPrefixID && this.$route.fullPath.indexOf('edit') > 0) {
        this.isEditing = true;
        await this.getOneAvailablePrefix(this.allowedPrefixID)
        this.loaded = true
      }
      else{
        this.loaded = true
      }
      this.$title = "Allowed Prefixes";
    },
    watch: {
      setting: {
        handler() {
          this.checkValid(this.setting);
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      checkValid(values) {
        this.invalid = false;
        if (values.prefix && (values.prefix?.split('.')?.length !==4 || values.prefix?.split('/')?.length < 1 || values.prefix?.split('/')?.length > 2 || Number(values.prefix?.split('/')[1]) < 0 || Number(values.prefix?.split('/')[1]) > 24)) this.invalid = true;
        return this.invalid;
      },
      _d(time) {
        return getFullFormatDateFromISOString(time);
      },
      async getOneAvailablePrefix(id) {
        const url = `announcement`;
        try {
          const reply = await AllowedPrefixesService.getAllIPAnnoucements(url);
          if (reply.status == 200 && reply.data) {
            this.allPrefixes = reply.data;
            this.setting = this.allPrefixes.find(x => x.id == id)
            console.log(this.setting)
          }
          // else{
          //   notify("Error getting Allowed Prefix", "error");
          // }
        }catch {
          notify("Error getting Allowed Prefix or does not exist", "warning");
          this.$router.push("/allowed-prefixes");
        }
        
      },
      async saveSettings() {
        var action = {present: "update", past: "updated"};
        var url = `announcement/${this.allowedPrefixID}`;
        var reply = {};
        try{
          console.log(this.setting)
          if (!this.isEditing) {
            action = {present: "addition", past: "added"};
            url = `announcement`;
            delete this.setting.id
            delete this.setting.uid
            reply = await AllowedPrefixesService.addIPAnnoucement(
              url,
              this.setting
            );
          }
          else {
            action = {present: "update", past: "updated"};
            url = `announcement`;
            delete this.setting.id
            reply = await AllowedPrefixesService.updateIPAnnoucement(
              url + '/' + this.allowedPrefixID,
              this.setting
            );
          }
          if (reply.status == 200 && reply.data) {
            this.setting = reply.data;
            notify(`Allowed Prefix ${action.past} successfully`);
            this.$router.push("/allowed-prefixes");
          }
        }
        catch(error) {
          var text = `Allowed Prefix ${action.present} failed`;
          notify(text, 'error')
        }
      },
      logOut() {
        this.$store.dispatch("auth/logout");
        this.$router.push("/login");
      },
    },
  };
</script>
<style scoped>
  .bg-secondary {
    background-color: #f7fafc !important;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    scale: 0.7;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
</style>
